import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfURL, bookcover,articlepdfURL } from '../../api/api';
import PDFViewer from '../../component/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import Header from '../../component/pageheader/pageHeader';
import { Spin, Col, Row,Breadcrumb,message,Progress} from 'antd';
import { useIndexedDB } from 'react-indexed-db';
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { useHistory,useLocation } from 'react-router-dom'
import Bookcover from '../../api/images/book.png';
import Axios from "axios";
import { ASSET_URL, SERVER_URL } from '../../api/content-novareader';
import CryptoJS from 'crypto-js';

const Pdf = (props) => { 
  const history = useHistory();
  const nlocation = useLocation();
  const contentid=nlocation.state.contentid
  const fuzzyid= nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=props.match.params.elasticid
  const isonline=nlocation.state.isonline
  const type=nlocation.state.type
  const cover=nlocation.state.cover
  const isorg=nlocation.state.isorg
  const bookinfo=nlocation.state.info
  const author=nlocation.state.auth
  const org = JSON.parse(localStorage.getItem('lpporg'));
  const { add, getAll } = useIndexedDB('book');
  
  const [location, setlocation] = useState('')
  const [loading, setloading] = useState(false)
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
 const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  useEffect(() => {
    setloading(true);
    findByNameAndType(titel, 'pdf').then(results => {
      if (results.length>0) {
        message.success('Loading ....');
        var file = results[0];
        blobToString(file.file)
          .then((encryptedData) => {
            // Step 3: Decrypt the encrypted data

            const aesKey = CryptoJS.enc.Utf8.parse("1234567890123456"); 
						const chunkSize = 1024 * 7000; // Size of each chunk in bytes (64KB)
						let chunksArray = [];  // Array to store chunk data
								
            // Split the ArrayBuffer into chunks
            for (let i = 0; i < encryptedData.byteLength; i += chunkSize) {
              const chunk = encryptedData.slice(i, i + chunkSize);
              const wordArrayChunk = CryptoJS.lib.WordArray.create(chunk);

              // Store the wordArrayChunk in the chunksArray (avoid concat for large files)
              if (wordArrayChunk.sigBytes > 0) {
                chunksArray.push(wordArrayChunk);
              }
            }
            let encryptedWordArray = CryptoJS.lib.WordArray.create();  // Initialize an empty WordArray

            chunksArray.forEach(chunk => {
              encryptedWordArray.concat(chunk);  // Concatenate chunks after they are all collected
            });
        
                // Perform Decryption
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: encryptedWordArray },
                aesKey,
                {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                }
            );
    
            // Validate Decryption
            if (decrypted.sigBytes <= 0) {
                throw new Error('Decryption failed. No valid data produced.');
            }
    
            const byteArray = new Uint8Array(decrypted.sigBytes);
            for (let i = 0; i < decrypted.sigBytes; i++) {
                byteArray[i] = (decrypted.words[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xff;
            }
  
            const epubBlob = new Blob([byteArray]);
            const url = URL.createObjectURL(epubBlob);
            localStorage.setItem('pdfurl', url);
            setlocation(epubBlob)
            setloading(false)
    
          });
      } else {
        const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
        getdata(lpp_userCredentials.apiKey);
      }
    });
  }, [])

  const blobToString = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result); // Read as string
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob); // Read Blob as text
    });
  };

  async function findByNameAndType(name, type) {
    try {
      const allRecords = await getAll(); // Get all records from the 'book' store
      
      // Filter by name and type
      const results = allRecords.filter(
        (record) => record.name === name && record.type === type
      );
      return results;
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  }
  
  const getdata = (apikey) => {
    message.success('Downloading ....');
  
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
  
    Axios.get(`${SERVER_URL}content/${type}/webpdf/${fuzzyid}.pdf`, {
      responseType: "arraybuffer",
      headers: { 'apikey': apikey },
      ...options,
    })
      .then((encryptedData) => {
        Axios.get(`${ASSET_URL}${fuzzyid}/${cover}`, { responseType: "blob" })
          .then((response) => {
            const content = encryptedData.data;
  
            // Step 3: Decrypt the encrypted data
            const aesKey = CryptoJS.enc.Utf8.parse("1234567890123456"); 
						const chunkSize = 1024 * 7000; // Size of each chunk in bytes (64KB)
						let chunksArray = [];  // Array to store chunk data
								
            // Split the ArrayBuffer into chunks
            for (let i = 0; i < content.byteLength; i += chunkSize) {
              const chunk = content.slice(i, i + chunkSize);
              const wordArrayChunk = CryptoJS.lib.WordArray.create(chunk);

              // Store the wordArrayChunk in the chunksArray (avoid concat for large files)
              if (wordArrayChunk.sigBytes > 0) {
                chunksArray.push(wordArrayChunk);
              }
            }
            let encryptedWordArray = CryptoJS.lib.WordArray.create();  // Initialize an empty WordArray

            chunksArray.forEach(chunk => {
              encryptedWordArray.concat(chunk);  // Concatenate chunks after they are all collected
            });
        
                // Perform Decryption
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: encryptedWordArray },
                aesKey,
                {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                }
            );
       
            // Validate Decryption
            if (decrypted.sigBytes <= 0) {
                throw new Error('Decryption failed. No valid data produced.');
            }
    
            const byteArray = new Uint8Array(decrypted.sigBytes);
            for (let i = 0; i < decrypted.sigBytes; i++) {
                byteArray[i] = (decrypted.words[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xff;
            }
  
            const epubBlob = new Blob([byteArray]);
            const url = URL.createObjectURL(epubBlob);
            localStorage.setItem('pdfurl', url);
  
            // IndexedDB Insert
            const encryptedBlob = new Blob([content], { type: 'application/octet-stream' });

            add({
              name: titel,
              file: encryptedBlob,
              img: response.data,
              type: "pdf",
              info: bookinfo,
              auth: author,
            })
              .then(() => {
                setlocation(epubBlob)
                setloading(false)
              })
              .catch((error) => {
                setlocation(epubBlob)
                setloading(false)
              });
          })
          .catch((error) => {
            console.error("Cover Image Fetch Error:", error);
            message.error('Failed to fetch cover image.');
            setloading(false);
          });
      })
      .catch((error) => {
        console.error("EPUB Fetch Error:", error);
        message.error('Sorry, something went wrong. Please try again!');
        setTimeout(() => {
          history.goBack();
        }, 3000);
      });
  };    


if (loading) {
    return (
      <div>
 <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{" "}</Breadcrumb.Item>
             </Breadcrumb>              <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }}>{titel}</p>
                  <img src={bookcover + fuzzyid + "/"+cover} style={{ height: '80%', width: '100%', backgroundColor: '#cccccc' }} alt="" loading="lazy" />
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                    <Progress percent={downloadInfo.progress} size="default" status="active"  />
                    <p style={{ color: '#000', letterSpacing: '1px', marginTop: '10px', marginLeft: '10px',fontFamily:'var(--font)' }}><span style={{ color: '#1890ff',}}> {formatBytes(downloadInfo.loaded)}</span> / {formatBytes(downloadInfo.total)}</p>
                  </div>
                </Col>
              </Row>
            </div>
    )
  }
  return (
    <PDFViewer
      backend={PDFJSBackend}
      src={location}
    />
  )
}

export default Pdf;