import Logo from './images/nova.png';
import Loginimg from './images/computer.png';
export const SERVER_URL = "https://demo.novareader.co/";
export const ASSET_URL = "https://demo.novareader.co/unsecured/";
export const REST_API_URL = SERVER_URL+"api/v1";
export const APP_NAME="Nova Reader";
export const LOGO=Logo;
export const LOGINIMG=Loginimg;
export const FbLoginID= 1007931699847440;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com";
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.novareader.co/login";
export const URL="https://mobile.novareader.co/";
export const AppVersion="0.5.1";
export const RedirectUri="https://content.novareader.co/api/applelogin";
export const Cover="https://demo.novareader.co/unsecured/";







