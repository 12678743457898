import React, { useState } from "react";
import { Form, Input, Button, Checkbox,Modal,message } from 'antd';
import{useHistory}from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import validator from 'validator';
import axios from "axios";
import { REST_API_URL } from "../../api/content-novareader";

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
const NormalLoginForm = () => {
  const history=useHistory();
  const [loadings, setloadings] = useState(false);
  const [btnText, setbtnText] = useState("Login");


  const onFinish = async (values) => {
    if(validator.isEmail(values.username)){
      setloadings(true);
      setbtnText("Loading")

      let json = {
        email: values.username.toLowerCase(),
        password: values.password
      };
      try {
        const response = await axios.post(REST_API_URL+'/auth/login', json, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        const loginRes = await checkLogin(response.data);
        if (loginRes == true) {
          history.replace("/")     
        } else {
          setloadings(false);
          setbtnText("Login");       
        }
      } catch (error) {
        Modal.error({
          title: 'Login Failed',
          content: error.response.data.message,
        });
        setloadings(false);
        setbtnText("Login");  
      }
    }else{
       message.error("Invalid Email")
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const  checkLogin = async (result) => {
    if (result.emailVerified==0) {
      console.log(result.email);
      let homePage = await getEmailVerification(result.email);
      console.log("finalresponse", homePage)
      if (homePage.status === 200) {
        Modal.info({
          title: 'Verification',
          content: 'A verification link has been sent to your email address, please click on the link to activate your account.',
        });
      }
      return false;
    }
    localStorage.setItem('lpp_userCredentials', JSON.stringify(result));
    return true;
  }
  const getEmailVerification = async (usermail) => {
    let json = {email: usermail.toLowerCase()};
    try {
      const response = await axios.post(REST_API_URL+'/auth/resend', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response;
    } catch (error) {
    }
  }


  return (
    <div>
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          //  label="Email"
          name="username"
          
          rules={[{required: true, message: 'Please input your username!' }]}
        >
          <Input placeholder="Email"  />
        </Form.Item>

        <Form.Item
          // label="Password"
          name="password"
          rules={[{required: true, message: 'Please input your password!' }]}
        >
         <Input.Password placeholder="Password"/>
        </Form.Item>

          <Form.Item style={{ "clear": "both",marginTop:'30px'}}>
          <Button className="loginBtn"  type="primary" htmlType="submit" block loading={loadings}>
          <span className="btntext">  {btnText}</span>
          </Button>
        </Form.Item>
      </Form>

    </div>
  );

};

export default NormalLoginForm;