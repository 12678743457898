import React, {Component} from "react";
import { Form, Input, Button, Checkbox, Empty, Modal,message } from 'antd';
import validator from 'validator';
import axios from "axios";
import { REST_API_URL } from "../../api/content-novareader";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class NormalForgotPasswordForm extends Component {
  constructor(){
      super();
      this.state={
        forgotVar:true, 
        verfiyVar:false,
        loadings:false,
        btnText:"Reset Password",
      }
  }
  onFinish = async(values) => {
    if(validator.isEmail(values.email)){

    this.setState({
      loadings:true,
      btnText:"Loading"
    })
    try {
      let json = {email: values.email.toLowerCase()};
      const response = await axios.post(REST_API_URL+'/auth/forgotpassword', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response);
      this.setState({forgotVar:false, verfiyVar:true});
      this.setState({
        loadings:false,
        btnText:"Reset Password"
      })
    } catch (error) {
      Modal.error({
        title: 'Forgot Password',
        content: error.response.data.message,
      });
      this.setState({
        loadings:false,
        btnText:"Reset Password"
      })
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
}



render() {
  const {forgotVar, verfiyVar} = this.state;
  return (
    <div>
      {forgotVar == true ? 
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          // label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
           <Input placeholder="Email"  />
           
        </Form.Item>
       
          
        <Form.Item style={{"clear":"both",marginTop:'30px'}}  >
          <Button className="loginBtn" type="primary"  htmlType="submit" block loading={this.state.loadings}>
        <span className="btntext">  {this.state.btnText}</span>
          </Button>
        </Form.Item>
      </Form>:""}
    {verfiyVar == true ?
    <Empty
          description={
            <span style={{marginBottom:"50px"}}>
              <span><b>Forgot Password</b></span>
              <br/>
              Email was sent with an reset password link.
            </span>
          }
        >
          {/* <Button type="primary">Create Now</Button> */}
    </Empty>:""}
    </div>
  );
}
};

export default NormalForgotPasswordForm;