import React, { useState, useEffect } from "react";
import { Modal} from 'antd';
import GoogleLogin from 'react-google-login';
import NormalLoginForm from './NormalLoginForm';
import NormalSiginForm from './NormalSiginForm';
import InstLogin from './instLoginForm';
import { AppleLogin } from 'react-sign-in-apple';
import { useHistory } from 'react-router-dom';
import orcid from '../../asset/images/orcid.png';
import { ArrowLeftOutlined, LoadingOutlined,BankOutlined } from '@ant-design/icons';
import NormalForgotPasswordForm from './NormalForgotPasswordForm';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { resendMail, googlesignin, fblogin, logo, fbloginid, url, orcidlogin, applelogin, appleclientid, gmailclientid,orcidclientid ,redirectUri} from '../../api/api';
import { FacebookLoginButton, GoogleLoginButton, AppleLoginButton, createButton, createSvgIcon } from "react-social-login-buttons";
import './styles.css';
import axios from "axios";
import { REST_API_URL } from "../../api/content-novareader";

const Login = (props) => {
  const history = useHistory();
  const [signupForm, setsignupForm] = useState(false);
  const [loginForm, setloginForm] = useState(true);
  const [forgotForm, setforgotForm] = useState(false);
  const [isgmaillogin, setisgmaillogin] = useState(false);
  const [instForm, setinstForm] = useState(false);
  const [isfblogin, setisfblogin] = useState(false);
  const [isorcid, setisorcid] = useState(false)
  const [isapplelogin, setisapplelogin] = useState(false);

  const config = {
    text: "Log in with Orcid",
    icon: createSvgIcon(orcid),
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#87F575" },
    activeStyle: { background: "#87F575" }
  };
  const ORCIDLoginButton = createButton(config);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('code')
    const appletoken = query.get('token')
    if (token) {
      setisorcid(true)
      getOrcidtoken(token)
    }else if(appletoken){
      setisapplelogin(true)
      getappletoken(appletoken)

    }
     else {
      const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
      if (lpp_userCredentials != null) {
        if (lpp_userCredentials.emailVerified == 1) {
          history.replace("/")
        }
      }
    }

  }, [])

  const signUp = () => {
    setsignupForm(true);
    setloginForm(false);
    setforgotForm(false)
    setinstForm(false)

  }
  const backtoLogin = () => {
    setsignupForm(false);
    setloginForm(true);
    setforgotForm(false)
    setinstForm(false)

  }
  const forgotPassword = () => {
    setsignupForm(false);
    setloginForm(false);
    setforgotForm(true)
    setinstForm(false)

  }

  const instLogin = () => {
    setsignupForm(false);
    setloginForm(false);
    setforgotForm(false)
    setinstForm(true)

  }



  const getOrcidtoken = async (code) => {
    var formdata = new FormData();
    formdata.append("token", code)
    formdata.append("redirect_uri", url+"login")
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    let data = await fetch(orcidlogin, requestOptions)
    let result = await data.json()
    const loginRes = await checkLogin(result);
    if (loginRes == true) {
      history.replace("/")
    } else {
      setisorcid(false)
      window.location.href = url+"login";
    }
}
const getappletoken = async (code) => {
  var formdata = new FormData();
  formdata.append("token", code)
  formdata.append("redirect_uri", redirectUri)
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  let data = await fetch(applelogin, requestOptions)
  let result = await data.json()
  const loginRes = await checkLogin(result);
  if (loginRes == true) {
    history.replace("/")
  } else {
    setisapplelogin(false)
    window.location.href = url+"login";
  }
}

  const responseGoogle = async (response) => {
    if (response != undefined && response.error == undefined) {
      setisgmaillogin(true)
      let json = {token: response.tokenId};
      try {
        const response = await axios.post(REST_API_URL+'/auth/googlesignin', json, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setisgmaillogin(false);
  
        const loginRes = await checkLogin(response.data);
        if (loginRes == true) {
          history.replace("/")     
        }
      } catch (error) {

      }



    }
  }
  
  const checkLogin = async (result) => {
    console.log("result", result)
    if (result.emailVerified==0) {
      console.log(result.email);
      let homePage = await getEmailVerification(result.email);
      console.log("finalresponse", homePage)
      if (homePage.status === 200) {
        Modal.info({
          title: 'Verification',
          content: 'A verification link has been sent to your email address, please click on the link to activate your account.',
        });
      }
      return false;
    }
    localStorage.setItem('lpp_userCredentials', JSON.stringify(result));
    return true;
  }

  const responseApple = async (response) => {
    setisapplelogin(true)
    var formdata = new FormData();
    formdata.append("token", response.accessToken)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    let data = await fetch(applelogin, requestOptions)
    let result = await data.json()
    const loginRes = await checkLogin(result);
    if (loginRes == true) {
      history.replace("/")
    } else {
      setisapplelogin(false)

    }
  }
  const responseFacebook = async (response) => {
    console.log("fbres", response)
    if (!isorcid) {
      if (response.status != "unknown") {
        setisfblogin(true)
        var formdata = new FormData();
        formdata.append("token", response.accessToken)
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        let data = await fetch(fblogin, requestOptions)
        let result = await data.json()
        const loginRes = await checkLogin(result);
        if (loginRes == true) {
          history.replace("/")
        }
      }
    }
  }

  const orcidOpen = () => {
     window.location.href = orcidclientid;
  }

  const getEmailVerification = async (usermail) => {
    var formdata = new FormData();
    formdata.append("email", usermail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    let data = await fetch(resendMail, requestOptions);
    let result = await data.json();
    return result
  }
  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  };
  return (
    <div className="login_continer" >
      <div className="loginPage">
        <div className="logImg">
          <img style={{ width: '140px', marginTop: '20px' }} src={logo} alt="" />
        </div>
        <div></div>
        {loginForm ? <div>
          <div className="loginHead">Login</div>
          {/* <div className="inst" onClick={instLogin}><BankOutlined style={{marginRight:'10px' }}/>Sign in through your Institution</div> */}

          <NormalLoginForm gologin={() => backtoLogin()} />
          <div className="login_fooder">
            <div className="login-foot">Don't has an account? <span onClick={signUp}>SignUp</span></div>
            <div className="forget-text" onClick={forgotPassword}>Forgot Password?</div>
          </div>
        </div> : ""}
        {signupForm ? <div>
          <div onClick={backtoLogin} className="loginHead"><ArrowLeftOutlined style={{ marginRight: '10px' }} />Create Account</div>
          <NormalSiginForm gologin={() => backtoLogin()} />
        </div> : ""}
        {forgotForm ? <div>
          <div onClick={backtoLogin} className="loginHead"><ArrowLeftOutlined style={{ marginRight: '10px' }} />Forgot Password</div>
          <NormalForgotPasswordForm />
        </div> : ""}
        {instForm ? <div>
          <InstLogin />
          <div className="login_fooder">
            <div className="login-foot" style={{textAlign:'center'}}>Can't find your institution?</div>
            <div className="forget-text" style={{textAlign:'center'}} onClick={backtoLogin}>Sign via username and password</div>
          </div>
        </div> : ""}
        <div style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#D7D4D3' }}>Or Login With</div>
        <div className="loginButton">
          <GoogleLogin
            clientId={gmailclientid}
            render={renderProps => (
              <GoogleLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "15px" }} onClick={renderProps.onClick} >
                {isgmaillogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px' }} /></span> : <span>Log in with Gmail</span>}
              </GoogleLoginButton>)}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
          <FacebookLogin
            autoLoad={false}
            appId={fbloginid}
            fields="name,email"
            isMobile={false}
            redirectUri={url}
            disableMobileRedirect={true}
            callback={responseFacebook}
            render={renderProps => (
              <FacebookLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "15px" }} onClick={renderProps.onClick} >
                {isfblogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px' }} /></span> : <span>Log in with Facebook</span>}
              </FacebookLoginButton>)} />
          <AppleLogin
            clientId={appleclientid}
            redirectURI={redirectUri}
            onSuccess={responseApple}
            onFailure={console.error}
            render={renderProps => (
              <AppleLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "15px" }} onClick={renderProps.onClick} >
                {isapplelogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px' }} /></span> : <span>Log in with Apple</span>}
              </AppleLoginButton>)} />
          <div onClick={orcidOpen} style={{ height: '40px', backgroundColor: '#fff', marginTop: '15px', borderRadius: '5px', display: 'flex', boxShadow: "1px 1px 3px 0.5px #9E9E9E", alignItems: 'center', marginLeft: '5px', marginRight: '5px' }}>
            <img style={{ width: '30px', }} src={orcid} alt="" />
            {isorcid ? <span> Loading... <LoadingOutlined style={{ marginLeft: '5px' }} /></span> : <span style={{ marginLeft: '5px' }}> Log in with Orcid</span>}
          </div>
        </div>
      </div></div>);

}

export default Login;